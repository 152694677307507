.container {
  height: 30px;
  width: 90px;
  border: 1px solid var(--primary-border);

  border-radius: 25px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.containerSmall {
  height: 25px;
  width: 80px;
  border: 1px solid var(--primary-border);

  border-radius: 25px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5px;
  cursor: pointer;
  transition: all 0.3s;
}
.handle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid var(--primary-border);
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: var(--text-mute);
  overflow: hidden;
}

.handleActive {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid var(--primary-border);
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: white;
  overflow: hidden;
}

.handleSmall {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid var(--primary-border);
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: var(--text-mute);
  overflow: hidden;
}

.handleActiveSmall {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid var(--primary-border);
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: white;
  overflow: hidden;
}

.container[data-darkmode="true"] {
  background-color: var(--primary-purple);
}

.containerSmall[data-darkmode="true"] {
  background-color: var(--primary-purple);
}

.textOn {
  margin-right: auto;
  padding-left: 5px;
  font-size: 12px;

  color: white;
}

.textOff {
  color: var(--text-body);
  margin-left: auto;
  font-size: 12px;

  padding-right: 5px;
}
