.loaderBox {
  margin-top: 20vh;
  width: 100%;
  text-align: center;
}

.loaderIcon {
  position: relative !important;
  height: auto !important;
  width: 15rem !important;
  object-fit: contain;
}

@media (max-width: 768px) {
  .loaderIcon {
    width: 80% !important;
  }
}
