.main {
  width: 100%;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}

.passwordInfo {
  color: var(--text-body);
  font-size: 12px;
  margin-left: 0.5em;
  margin-top: 0.5em;
}

.form {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 0rem 1rem;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.trash {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--primary-border);
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px;
  width: 30px;
  height: 30px;
  background-color: var(--primary-bg);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.trash:hover {
  cursor: pointer;
  opacity: 0.6;
  scale: 1.02;
}

.addButton {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-green-500);
  gap: 0.2rem;
  font-size: 14px;
}

.addButton:hover {
  cursor: pointer;
  color: var(--primary-green-400);
}

.title {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.input {
  border-radius: 8px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-top: 26px;
  line-height: 1.25;
  font-size: 14px;
  border: 1px solid var(--primary-border);
}

.inputWrapper {
  width: 100%;
  height: fit-content;
  position: relative;
  margin-bottom: 0px;
}

.inputHeader {
  color: var(--text-body);
  position: absolute;
  left: 17px;
  top: 7px;
  font-size: 13px;
}

/* .input:focus,
input:active {
  outline: none;
  border: 1px solid var(--primary-green-500);
} */

.input::placeholder {
  font-size: 12px;
}

.space {
  margin-top: 0.5rem;
}

.space1 {
  margin-top: 1rem;
}

.buildingTypeButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.buildingTypeButton {
  width: 49%;
  border: 1px solid var(--primary-border);

  background-color: white;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  height: 100%;
  padding-left: 12px;
  padding-right: 12px;
  height: 48px;
}

.buildingTypeButton:hover {
  border: 1px solid var(--primary-purple);
}

.buildingTypeButtonSelected {
  width: 49%;
  border: 1px solid var(--primary-border);

  background-color: var(--primary-purple);
  color: white;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  height: 100%;
  padding-left: 12px;
  padding-right: 12px;
  height: 48px;
}

.buildingTypeButtonSelected:hover {
  border: 1px solid var(--primary-purple);
}

.col {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.formData {
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  padding-top: 12px;
}

.leftSection {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.rightSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
}

.editButton {
  display: flex;
  position: relative;
  flex-direction: row;
  text-transform: capitalize;
  align-items: center;
  cursor: pointer;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  padding: 7px 10px;
  height: fit-content;
  font-family: "Editorial";
  transition: 0.2s ease-in-out;
}

.editButton:hover {
  opacity: 0.8;
  background-color: var(--primary-bg-dark);
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 12px;
  gap: 5px;
}

.cancelButton {
  display: flex;
  position: relative;
  flex-direction: row;
  text-transform: capitalize;
  align-items: center;
  cursor: pointer;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  padding: 7px 10px;
  height: fit-content;
  font-family: "Editorial";
  transition: 0.2s ease-in-out;
}

.cancelButton:hover {
  background-color: var(--primary-bg-dark);
}

.submitButton {
  display: flex;
  position: relative;
  height: fit-content;
  text-transform: capitalize;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: var(--text-main);
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  color: white;
  padding: 7px 10px;
  font-family: "Editorial";
  width: fit-content;
  transition: 0.2s ease-in-out;
}

.submitButton:hover {
  opacity: 0.8;
}

.width {
  margin-left: auto;
}

.check {
  margin-right: 0.5rem;
}

.deleteButton {
  display: flex;
  position: relative;
  height: fit-content;
  text-transform: capitalize;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: var(--primary-red-500);
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  color: white;
  padding: 7px 10px;
  font-family: "Editorial";
  transition: 0.2s ease-in-out;
}

.deleteButton:hover {
  opacity: 0.8;
}

.inputTitle {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
  color: var(--text-main);
}

.inputValue {
  font-size: 13px;
  color: var(--text-body);
  margin-bottom: 0rem;
  font-weight: 400;
}

.img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid var(--primary-border);
  cursor: pointer;
}
.separator {
  border-bottom: 1px solid var(--primary-border);
}

@media (max-width: 600px) {
  .title {
    margin-left: 1.5rem;
  }
}
