.main {
	width: 100%;
	min-height: 100vh;
	padding-bottom: 5rem;
	padding-top: 2.5rem;

	background-color: var(--primary-bg);
}

.passwordInfo {
	color: gray;
	font-size: 14px;
	margin-left: 0.5em;
	margin-top: 0.5em;
}

.form {
	width: 50%;
	background-color: white;
	border-radius: 8px;
	padding: 0rem 1rem;
	margin-left: auto;
	margin-right: auto;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
		rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.title {
	width: 50%;
	margin-left: auto;
	margin-right: auto;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 1rem;
}

.input {
	border-radius: 8px;
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
	padding-bottom: 10px;
	padding-top: 26px;
	line-height: 1.25;
	border: 1px solid var(--primary-border);
}

.inputWrapper {
	width: 100%;
	height: fit-content;
	position: relative;
	margin-bottom: 10px;
}

.inputHeader {
	color: var(--text-body);
	position: absolute;
	left: 17px;
	top: 7px;
	font-size: 13px;
}

/* .input:focus,
input:active {
	outline: none;
	border: 1px solid var(--primary-green-500);
} */

.input::placeholder {
	font-size: 12px;
}

.space {
	margin-top: 0.5rem;
}

.space1 {
	margin-top: 1rem;
}

.buildingTypeButtons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.buildingTypeButton {
	width: 49%;
	border: 1px solid var(--primary-border);

	background-color: white;
	border-radius: 8px;
	font-size: 15px;
	font-weight: 500;
	height: 100%;
	padding-left: 12px;
	padding-right: 12px;
	height: 48px;
}

.buildingTypeButton:hover {
	border: 1px solid var(--primary-purple);
}

.buildingTypeButtonSelected {
	width: 49%;
	border: 1px solid var(--primary-border);

	background-color: var(--primary-purple);
	color: white;
	border-radius: 8px;
	font-size: 15px;
	font-weight: 500;
	height: 100%;
	padding-left: 12px;
	padding-right: 12px;
	height: 48px;
}

.buildingTypeButtonSelected:hover {
	border: 1px solid var(--primary-purple);
}

.col {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.formData {
	display: flex;
	flex-direction: row;
	padding-bottom: 12px;
	padding-top: 12px;
}

.leftSection {
	display: flex;
	flex-direction: column;
	width: 80%;
}

.rightSection {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 20%;
}

.editButton {
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
		rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	background-color: var(--primary-bg);
	border-radius: 8px;
	font-size: 15px;
	font-weight: 500;
	height: 100%;
	padding-left: 12px;
	padding-right: 12px;
	height: 36px;
}

.editButton:hover {
	background-color: var(--primary-bg-dark);
}

.buttons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding-top: 12px;
}

.cancelButton {
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
		rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	background-color: var(--primary-bg);
	border-radius: 8px;
	font-size: 15px;
	font-weight: 500;
	height: 100%;
	padding-left: 12px;
	padding-right: 12px;
	height: 36px;
}

.cancelButton:hover {
	background-color: var(--primary-bg-dark);
}

.submitButton {
	margin-left: 0.5rem;
	background-color: var(--primary-purple);
	color: white;
	border-radius: 8px;
	font-size: 15px;
	font-weight: 500;
	height: 100%;
	padding-left: 12px;
	padding-right: 12px;
	height: 36px;
}

.submitButton:hover {
	background-color: var(--primary-green-500);
}

.width {
	width: 50%;
	padding-top: 16px;
	margin-left: auto;
	margin-right: auto;
}

.check {
	margin-right: 0.5rem;
}

.deleteButton {
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
		rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	background-color: var(--primary-bg-light);
	border-radius: 8px;
	font-size: 15px;
	font-weight: 500;
	height: 100%;
	padding-left: 12px;
	padding-right: 12px;
	height: 36px;
}

.deleteButton:hover {
	background-color: var(--primary-bg);
}

.inputTitle {
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 5px;
	color: var(--text-main);
}

.inputValue {
	font-size: 13px;
	color: var(--text-body);
	margin-bottom: 0rem;
}

.img {
	width: 100px;
	height: 100px;
	object-fit: cover;
	border-radius: 8px;
	border: 1px solid var(--primary-border);
	cursor: pointer;
}
.separator {
	border-bottom: 1px solid var(--primary-border);
}

@media (max-width: 600px) {
	.title {
		text-align: center;
		width: 95%;
	}

	.form {
		width: 95%;
	}

	.width {
		width: 95%;
	}
}
