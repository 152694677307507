.settingsContainer {
  display: flex;
  flex-direction: column;
  margin: 1.5em;
  min-height: 100vh;
  height: 100%;
  width: 100%;

  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

.main {
  display: flex;
  flex-direction: row;
  height: auto;
}

.tagLabel {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0rem;
  color: var(--text-body);
  text-transform: uppercase;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.leftMenu {
  height: auto;
  width: 30%;
  border: 1px solid var(--primary-border);
  border-radius: 3px;
  height: fit-content;
}

.profileSection {
  padding: 1rem;
  background-color: var(--primary-bg);
  display: flex;
  column-gap: 1rem;
  flex-direction: row;
  border-bottom: 1px solid var(--primary-border);
}

.rightProfileSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  row-gap: 0.2rem;
}

.row {
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.profileText {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.profileTextMute {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-mute);
  margin-bottom: 0rem;
}

.profileImg {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
  border: 1px solid var(--primary-border);
}

.edit {
  font-size: 12px;
  text-decoration: underline !important;
  color: var(--text-mute);
}

.edit:hover {
  color: var(--text-main);
}

.navigation {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  color: #99a3a6;
  margin-bottom: 2rem;
}

.link {
  text-decoration: none;
  color: #6b6b6b;
}

.link:hover {
  cursor: pointer;
  color: #222222;
  text-decoration: underline;
}

.link1 {
  text-decoration: none;
  color: #222222;
}

.link1:hover {
  cursor: pointer;
  color: #222222;
  text-decoration: underline;
}

.rightMenu {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: fit-content;
  padding-left: 2rem;
  padding-right: 2rem;
  row-gap: 2rem;
}
.bottomSection {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 3rem;
}

.top {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.gap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.logOutButton {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: var(--text-mute);
}

.logOutButton:hover {
  color: var(--text-main);
}

.tabTitle {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: var(--text-main);
}

.activeTitle {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  padding: 0.5rem 1rem;
  border-radius: 5px;

  cursor: pointer;
  background-color: var(--primary-bg);
  color: var(--text-main);
}

.ellipse {
  position: absolute;
  left: 0.3rem;
  top: 50%;
  transform: translateY(-50%);
}
.tabTitle:hover {
  background-color: var(--primary-bg);
}

.tabTitle:active {
  background-color: var(--primary-bg-light);
}

.activeTitle:hover {
  background-color: var(--primary-bg);
}

.activeTitle:active {
  background-color: var(--primary-bg-light);
}

.backArrow {
  display: none;
}

@media (max-width: 600px) {
  .settingsContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .backArrow {
    height: 30px;
    width: 30px;
    border: 1px solid var(--text-main);
    border-radius: 9999px;
    padding: 5px;
    display: flex;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
  }

  .rightMenu {
    padding-left: 0;
    padding-right: 0;
  }
  .main {
    flex-direction: column-reverse;
  }

  .leftMenu {
    width: 100%;
  }

  .rightMenu {
    width: 100%;
  }
}
