/* Profile */

.main {
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 2rem;
}
.section1 {
  display: flex;
  align-items: center;
  height: 10rem;
}

.shopsInfoBanner {
  display: flex;
  align-items: center;
  border-radius: 7px;
  background-color: var(--primary-green-100);
  border: 1px solid var(--primary-green-500);
  padding: 1rem 1.5rem;
  font-size: 18px;
}

.tagSelected {
  background-color: var(--primary-bg);
  border-radius: 5px;
  border: 1px solid var(--text-main);
  white-space: nowrap;
  font-size: 13px;
  padding: 5px 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.tagUnselected {
  background-color: var(--primary-bg);
  border-radius: 5px;
  border: 1px solid var(--primary-border);
  white-space: nowrap;
  font-size: 13px;
  padding: 5px 10px;
  cursor: pointer;
  text-transform: capitalize;
}

.tagSelected:hover {
  opacity: 0.8;
}

.tagUnselected:hover {
  border: 1px solid var(--text-body);
  opacity: 0.8;
}

.onHold {
  pointer-events: none;
  opacity: 0.3;
}

.gap2 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 1.5rem;
}

.primaryText {
  color: var(--text-main);
  margin-bottom: 0rem;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.subText {
  color: var(--text-mute);
  font-size: 14px;
  margin-bottom: 0rem;
  font-weight: 300;
}

.featuredImgPlaceholder {
  background-color: var(--primary-bg);
  position: relative;
  border-radius: 3px;
  width: 100%;
  aspect-ratio: 2/1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--text-mute);
  border: 1px solid var(--primary-border);
  overflow: hidden;
}

.featuredImg {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

.avatarPlaceholder {
  background-color: var(--primary-bg);
  position: relative;
  border-radius: 3px;
  width: 8em;
  height: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--text-mute);
  border: 1px solid var(--primary-border);
  overflow: hidden;
}

.img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

.imgDelete {
  color: var(--text-main);
  height: 1rem;
  width: 1rem;
}

.imgText {
  font-weight: 500;
  font-size: 12px;
  color: var(--text-main);
}

.imgText:hover {
  cursor: pointer;
  text-decoration: underline;
}

.imgDelete:hover {
  cursor: pointer;

  color: var(--text-main);
}

.infoText {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: var(--text-body);
  margin-bottom: 0rem;
  line-height: 150%;
}

.addShopDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: white;
  width: fit-content;
  padding: 0.3rem 0.7rem;
  font-size: 14px;
  border-radius: 5px;
  background-color: var(--primary-purple);
  cursor: pointer;
  margin-bottom: 0rem;
  font-family: "Editorial";
  margin-left: auto;
}

.inputWrapper {
  position: relative;
}

.inputDescriptor {
  position: absolute;
  left: 40px;
  top: 50%;
  font-size: 14px;
  color: var(--text-mute);
  transform: translateY(-50%);
}

.addShopDiv:hover {
  opacity: 0.8;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.shopDivPreview {
  border-radius: 7px;
  border: 1px solid var(--primary-border);
  background-color: white;
  padding: 1rem 1.5rem;
  cursor: pointer;
}

.halfDiv {
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
}

.infoIcon {
  height: 15px;
  width: 15px;
  min-height: 15px;
  min-width: 15px;
  color: var(--text-body);
}

.infoIconOverlay {
  height: 18px;
  width: 18px;
  color: var(--text-body);
}

.edit {
  display: flex;
  position: relative;
  flex-direction: row;
  text-transform: capitalize;
  align-items: center;
  cursor: pointer;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  padding: 7px 10px;
  height: fit-content;
  font-family: "Editorial";
  transition: 0.2s ease-in-out;
}

.edit:hover {
  background-color: var(--primary-bg-dark);
}

.edit:active {
  background-color: var(--primary-bg-light);
}

.infos {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
}

.section {
  border: 1px solid var(--primary-border);
  background-color: white;
  border-radius: 5px;
}

.topSectionShop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--primary-bg);
  /* background-color: var(--primary-bg); */
}

.head {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.justifyBetweenBg {
  border-bottom: 1px solid var(--primary-bg);
  background-color: var(--primary-bg);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.sectionInfoDel {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: var(--primary-bg);
}

.bottomSection {
  padding: 1rem 1.5rem;
}

.deliveryBox {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 0.5rem;
}

.space {
  margin-top: 1rem;
}

.infoSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.separator {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--primary-border);
}

.sectionStore {
  margin-bottom: 2.5rem;
}
.section3 {
  display: flex;
  flex-direction: column;
  margin-top: -1rem;
  margin-bottom: 2rem;
}

.addMore {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-purple);
  font-size: 14px;
}

.subtractDel {
  top: 0px;
  width: 1.5rem;
  height: 1.5rem;
  right: 0px;
  z-index: 5;
  position: absolute;
  color: var(--text-body);
}

.subtractDel:hover {
  cursor: pointer;
  transform: rotate(-5deg);
}

.plusIcon {
  margin-right: 1rem;
}

.addMore:hover {
  cursor: pointer;
  color: var(--primary-red-500);
}

.inputBox {
  margin-right: 5px;
}
.profilePic {
  border-radius: 100%;
  height: 8em;
  width: 8em;
  object-fit: cover;
}

.timePickerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.datePicker {
  background-color: var(--primary-bg);
  color: black;
  width: 50px;
  border: none;
  cursor: pointer;
}

.datePickerDash {
  margin-left: 10px;
  margin-right: 10px;
}

.datePickerWrapper {
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  height: 49px;
  border: 1px solid var(--primary-border);
  width: 80px;
  width: 80px;
  border-radius: 3px;
}

.datePickerText {
  position: absolute;
  background-color: white;
  color: var(--text-mute);
  top: -8px;
  left: 6px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 10px;
}

.datePickerText1 {
  position: absolute;
  background-color: var(--primary-bg);
  color: var(--text-mute);
  top: -8px;
  left: 6px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 10px;
}

.datePicker1 {
  width: inherit;
  cursor: pointer;
  border: none;
  font-size: 14px;
  background-color: transparent;
}

.datePicker1:focus {
  outline: none;
}

.datePickerWrapper:hover {
  cursor: pointer;
  border: 1px solid var(--text-body);
}

.datePickerWrapper:hover span {
  color: var(--text-main);
}

.infoSubText {
  font-size: 16px;
}

.checkBox {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.inputAccept {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background-color: white;
  color: var(--primary-purple);
  border-radius: 5px;
  min-width: 64px;
  width: 4rem;
  height: 3rem;
  font-weight: 600;
  border: 1px solid var(--primary-purple);
}

.inputAccept:hover {
  cursor: pointer;
}

.inputAcceptOn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background-color: var(--primary-purple);
  color: white;
  border-radius: 5px;
  min-width: 64px;
  width: 4rem;
  height: 3rem;
  font-weight: 600;
  border: 1px solid var(--primary-purple);
}

.inputAcceptOn:hover {
  cursor: pointer;
}

.pickupsAndViewings {
  padding: 1rem;
  border: 1px solid var(--primary-border);
  display: inline-block;
  width: 48%;
}

.colSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  margin-bottom: auto;
}

.colSectionBg {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  margin-bottom: auto;
  background-color: var(--primary-bg);
  padding: 0.5rem;
  border-radius: 3px;
  border: 1px solid var(--primary-border);
}
.timePickerSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2rem;
}

.boldSubText {
  font-weight: 600;
}

.gap {
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
}

.gap1 {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.rowGap {
  display: flex;
  position: relative;
  flex-direction: row;
  column-gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
}

.rowGap1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2px;
}

.gapSpace {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.title {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-main);
  margin-bottom: 0rem;
  background-color: transparent;
}
.title::placeholder {
  color: var(--text-mute);
}

.arrowUp {
  margin-left: auto;
}

.arrowDown {
  margin-left: auto;
  transform: rotate(180deg);
}

.activeSettings {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;
}

.activeSettings1 {
  padding: 1rem;
  border: 1px solid var(--primary-border);
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.subTitle {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 1px solid var(--primary-border);
  border-top: 1px solid var(--primary-border);

  margin-bottom: 1rem;
  padding: 1rem 0rem;
  font-size: 20px;
}

.subTitile1 {
  font-weight: 600;
  font-size: 18px;
}

.percentage {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
}

.percentageInput {
  padding-left: 0.5rem;
  width: 5rem;
  height: 3rem;
}

.listingPicture {
  object-fit: cover;
  max-height: 10rem;
  max-width: 10em;
  width: 10rem;
  height: 10rem;
}

.exampleContainer {
  display: flex;
  flex-direction: row;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 1em;
  padding: 1rem;
  width: fit-content;
  min-width: 50%;
}

.col {
  display: flex;
  flex-direction: column;
}

.listingTitle {
  font-weight: 600;
}

.price {
  width: 20%;
  display: inline-block;
  color: var(--text-main);
  font-size: 18px;
  vertical-align: top;
}

.bottomRow {
  margin-top: 1em;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.header1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.buttonDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 0.5rem;
}

.saveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: white;
  width: fit-content;
  padding: 0.3rem 0.7rem;
  font-size: 14px;
  border-radius: 5px;
  background-color: var(--primary-purple);
  text-transform: capitalize;
  font-family: "Editorial";

  cursor: pointer;
  margin-bottom: 0rem;
}

.saveButton:hover {
  opacity: 0.8;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.productCount {
  display: inline-block;
  font-weight: 200;
  color: gray;
}

.deleteButton {
  background-color: var(--primary-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: #222222;
  width: fit-content;
  padding: 0.3rem 0.7rem;
  font-size: 14px;
  border-radius: 5px;
  text-transform: capitalize;

  border: 1px solid var(--primary-border);
  font-family: "Editorial";
  cursor: pointer;
  margin-bottom: 0rem;
}

.modalText {
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 0rem;
}

.deleteButton:hover {
  opacity: 0.8;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.col {
  display: flex;
  flex-direction: column;
}

.removeIcon {
  color: #99a3a6;
  height: 25px;
  width: 25px;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  right: 1em;
}

.removeIcon:hover {
  cursor: no-drop;
}

.cardNumber {
  text-align: left;
  width: 50%;
  margin-left: 2em;
  /* margin-right: 3em; */
  color: #6b6b6b;
}

.cardLogo {
  max-height: 2em;
  max-width: 2em;
  display: inline;
  margin-left: 1em;
}

.cardGroupSize {
  width: 100%;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.cardPreviewAdd {
  display: flex;
  align-items: center;
  padding: 1em;
  color: var(--primary-purple);
}

.addIcon {
  color: var(--primary-purple);
  height: 24px;
  width: 24px;
  display: inline;
  margin-right: 0.75rem;
}

.addText {
  font-weight: 600;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
  height: 24px;
}

.paymentMethodSection {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.cardPreviewActive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  width: 100%;
  border: 0.5px solid var(--primary-purple);
  border-radius: 7px;
  margin-bottom: 0.5em;
  padding: 0.7em 0.4rem;
  color: gray;
  position: relative;
}

.cardPreview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  width: 100%;
  border: 0.5px solid rgba(189, 189, 189, 0.627);
  border-radius: 7px;
  margin-bottom: 0.5em;
  padding: 0.7em 0.4rem;
  color: gray;
  position: relative;
}

.subSubTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  line-height: 1.5;
  margin-bottom: 0rem;
  align-items: center;
  color: var(--text-body);
  font-weight: 200;
}

.sectionDel {
  display: flex;
  flex-direction: row;
  column-gap: 0.2rem;
  background-color: var(--primary-bg);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}
.row1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}

.justifyBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.subTitle1 {
  font-weight: 500;
  font-size: 18px;
}

.inputAccept {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background-color: white;
  color: var(--primary-purple);
  border-radius: 5px;
  min-width: 64px;
  width: 4rem;
  height: 3rem;
  font-weight: 600;
  border: 1px solid var(--primary-purple);
}

.inputAccept:hover {
  cursor: pointer;
}

.inputAcceptOn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background-color: var(--primary-purple);
  color: white;
  border-radius: 5px;
  min-width: 64px;
  width: 4rem;
  height: 3rem;
  font-weight: 600;
  border: 1px solid var(--primary-purple);
}

.inputAcceptOn:hover {
  cursor: pointer;
}

.cardDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.borderBox {
  padding: 1rem;
  border: 1px solid #cdced2;
  border-radius: 1px;
}

.backArrow {
  display: none;
}

@media (max-width: 600px) {
  .backArrow {
    height: 30px;
    width: 30px;
    border: 1px solid var(--text-main);
    border-radius: 9999px;
    padding: 5px;
    display: flex;
    margin-right: 0.5rem;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
  }

  .arrowBack {
    margin-left: 1.5rem;
  }
  .sectionStore {
    width: 100%;
  }

  .infoText {
    font-size: 12px;
  }

  .row {
    flex-wrap: wrap;
    row-gap: 1.5rem;
  }

  .colSection {
    width: 100%;
  }

  .exampleContainer {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .listingPicture {
    margin-bottom: 1rem;
  }
}

.feeInput {
  width: 5em;
  border: 1px solid var(--primary-border);
  border-radius: 3px;
  color: var(--text-main);
  font-size: 14px;
  padding-left: 5px;
}

.feeInput1 {
  width: 5em;
  border: 1px solid var(--primary-border);
  border-radius: 3px;
  font-size: 14px;

  color: var(--text-main);

  padding-left: 5px;
}
.bg {
  background-color: white;
  min-height: 100vh;
  padding-bottom: 4rem;
}

.checkMark {
  color: #159389;
}

.chackoutPage {
  max-width: 1452px;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-left: auto;
  margin-right: auto;
}

.discountCodeBox {
  width: 100%;
  border: none;
  background-color: var(--primary-bg);
  padding: 10px;
}

.discountCodeBox::placeholder {
  font-size: 14px;
}

.discountCodeBox:focus {
  outline: 1px solid var(--primary-purple);
}

.applyButton {
  background-color: var(--primary-purple);
  border: none;
  color: white;
  font-size: 14px;
}

.applyButtonNonActive {
  background-color: var(--primary-purple) 70;
  border: none;
  color: white;
  font-size: 14px;
}

.promoContainer {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.qty {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
  padding-left: 0.5rem;
}

.showBox1 {
  background-color: white;
  padding: 1rem;
  padding-bottom: 0rem;
}

.add {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #99a3a6;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  border: 0.5px solid #99a3a6;
  padding: 5px;
  cursor: pointer;
}

.quantityContainer {
  padding-left: 1rem;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.totalQty {
  color: #99a3a6;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}

.checkoutHeader {
  padding-top: 3.25rem;
  margin-bottom: 2rem;

  font-size: 32px;
  font-weight: 500;
}

.colGap1 {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.headerBox {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.paragraph {
  margin-bottom: 0rem;
  color: var(--text-mute);
  max-width: 50%;
  font-size: 14px;
}

.justifyBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.leftSide {
  width: 60%;
  display: inline-block;
}

.space2 {
  position: relative;
  padding-top: 1.5rem;
}
.box {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 80%;
  position: relative;
}

.spacing {
  margin-bottom: 2.25rem;
}

.continueButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 100%;
  height: 3rem;
  background-color: var(--primary-purple);
  color: white;
  margin-top: 1rem;
  border: 0;
  border-radius: 3px;
}

.continueButton:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-1px);
}

.box1 {
  border-bottom: 2px solid var(--primary-bg);
}

.showBox {
  background-color: rgb(237 248 247);
  border: 1px solid #159389;
  border-radius: 8px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.select {
  border: 1px solid var(--primary-purple);
  display: flex;
  width: fit-content;
  padding: 0.2rem;
  border-radius: 5px;
  flex-direction: row;
}

.selectBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  border: 1px solid white;

  padding: 0.2rem 0.4rem;
  border-radius: 5px;
}

.selectBox:hover {
  cursor: pointer;
}

.selectBoxActive {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.4rem;
  white-space: nowrap;

  border: 1px solid var(--primary-purple);
  background-color: var(--primary-purple);
  color: white;
  border-radius: 5px;
  font-size: 14px;
}

.selectBoxActive:hover {
  cursor: pointer;
}

.selectBoxError {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.4rem;
  white-space: nowrap;

  border: 1px dashed var(--primary-purple);
  background-color: white;
  color: var(--primary-purple);
  border-radius: 5px;
  font-size: 14px;
}

.selectBoxError:hover {
  cursor: pointer;
  background-color: var(--primary-purple);
  color: white;
}

.space1 {
  margin-right: 1rem;
}

.continueButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 100%;
  height: 3rem;
  background-color: var(--primary-purple);
  color: white;
  margin-top: 1rem;
  border-radius: 3px;
}

.continueButton:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-1px);
}

.input {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
  width: 45%;
  border: 1px solid #cdced2 !important;
  border-radius: 5px;
  font-size: 14px;

  background-color: white;
}

.inputRed {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 5px;

  height: 3rem;
  width: 45%;
  border: 0px;
  background-color: white;
  border: 1px dashed var(--primary-red-400);
}
.mainContainer {
  width: 35%;
  display: flex;
  margin-left: 2.5rem;

  flex-direction: column;
}

.errorDate {
  color: var(--primary-red-400);
  padding: 1em;
  font-size: 16px;
  border: 1px dashed var(--primary-red-400);
}

.inputSmall {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
  background-color: white;
  border: 1px dashed var(--primary-border);
  border-radius: 5px;
  padding: 7px 10px;
  font-size: 13px;
  font-family: "Editorial";
  transition: 0.2s ease-in-out;
}

.inputSmall:focus {
  outline: none;
  border: 1px solid var(--text-main);
}

.input1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
  background-color: white;
  border: 1px dashed var(--primary-border);
  border-radius: 5px;
  padding: 7px 10px;
  height: 8rem;
  font-size: 13px;
  font-family: "Editorial";
  transition: 0.2s ease-in-out;
}

.input1:focus {
  outline: none;
  border: 1px solid var(--text-main);
}

.dayText {
  font-size: 14px;
  color: var(--text-body);
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.mainText1 {
  position: relative;
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  color: var(--text-main);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0rem;
  min-width: 155px;
  width: 201px;
}

.mainText2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background-color: rgba(241, 250, 238, 0.321);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.mainText {
  background-color: rgb(249 249 251);
  border: 1px solid rgb(232 234 239);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0rem;
}

.mainTextActive {
  background-color: rgb(249 249 251);
  border: 1px solid #159389;
  color: #222222;

  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0rem;
}

.mainSubText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.timePickerText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0rem;
}

.subSubText {
  color: #222222;
  margin-bottom: 0rem;
  font-weight: 300;
  font-size: 14px;
}

.subSubTextGrey {
  color: #555555;
}

.reqIcon {
  color: var(--primary-red-400);
}

.borderBox {
  position: relative;

  padding-bottom: 1.5rem;
}
.borderBox1 {
  position: relative;
  padding-bottom: 0rem;
}

.editIcon {
  width: 2rem;
  height: 2rem;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 5px;

  padding: 2px;
  color: black;
}

.mainTextSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.checkoutSummary {
  width: 30%;
  margin-left: 2.5%;
  display: inline-block;
  min-height: 10em;
  vertical-align: top;
}

.subTextMain {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subTextMainStrike {
  color: rgb(0, 148, 37);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: line-through;
}

.total {
  font-weight: 600;
  font-size: 16px;
  border-top: 1px solid black;
  padding-top: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sellerBatch {
  background-color: white;
}

.whiteBox {
  background-color: white;
  padding: 1rem;
  margin-bottom: 1rem;
}

.infoBox {
  background-color: white;
  margin-bottom: 1rem;
}
.pickUpInfo {
  font-weight: 600;
  background-color: rgb(241, 250, 238);
  padding: 1rem;
}

.listingSummary {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.prices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
  font-size: 14px;
  border-bottom: 1px solid #99a3a6;
}

.finalPrices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
  font-size: 14px;
}

.listingInfo {
  padding-left: 1rem;
  display: inline-block;
  font-size: 14px;
}

.color {
  color: var(--primary-purple) !important;
}

.listingImg {
  position: relative;
  object-fit: cover;
  width: 4rem;
  height: 4rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.deliveryInfo {
  margin-bottom: 1em;
  position: relative;
  cursor: pointer;
}

.deliveryInfoDisabled {
  margin-bottom: 1em;
  position: relative;
}

.listingDisplay {
  display: flex;
  flex-direction: row;
  position: relative;
}

.listingImgDisplay {
  margin-right: -1rem;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  border: 1px solid var(--primary-border);
}

.batchDeliveryInfo {
  width: 65%;
  display: inline-block;
}

.timesInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.timesInputsBox {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.colGap {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.colGap1 {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.pointer {
  cursor: pointer;
  position: relative;
  color: black;
  min-width: 100%;
  margin-block: 5px;
  padding: 10px;
  background-color: white;
  border: 1px solid rgb(232 234 239);
}

.pointerError {
  cursor: pointer;
  position: relative;
  color: black;
  min-width: 100%;
  margin-block: 5px;
  padding: 10px;
  background-color: var(--primary-bg);
  border: 1px dashed var(--primary-red-400);
}

/* Layout */

.container {
  display: flex;
  width: 70%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  margin: auto;
  margin-top: 20px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.deliveryChoice {
  padding: 1rem;
}

.col {
  display: flex;
  justify-content: center;
}

.flexCol {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* text */

.instructions {
  font-size: 2.4vh;
  font-weight: 500;
  text-align: center;
  padding: 10px;
  height: auto;
}

.listingItem {
  font-size: 2.4vh;
  width: 100%;
  border-bottom: solid rgba(235, 235, 235, 0.822) 1.5px;
  padding: 17px;
}

.quantityInput {
  max-width: 100px;
}

/* listingImg */

.imgSize {
  width: 100%;
}

.carousel {
  width: 300px;
  display: inline-block;
}

/* Map */

.map {
  width: 100%;
  height: 100px;
}

/* Payment Stuff */

.paymentContainer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 40px;
  border-radius: 10px;
}

.navButtons {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
}

.cardGroupSize {
  width: 100%;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.arrowBack {
  height: 2rem;
  width: 2rem;
  /* margin-right: 1rem; */
  border: 1px solid var(--text-main);
  color: var(--text-main);
  padding: 5px;
  border-radius: 9999px;

  cursor: pointer;
}

.otherStoresCategoriesDiv {
  margin-left: 3em;
  margin-top: var(--space-mid);
  background-color: var(--primary-bg);
  padding: 1rem;
  border: 1px solid var(--primary-border);
}

.margin {
  margin-top: 1.25rem;
}

.catAndOp {
  padding: 0.3em;
  border: 0.3px solid gray;
  border-radius: 3px;
  display: inline-block;
  margin: 0.3em;
}

.storeTrailer {
  font-size: 0.8em;
  color: gray;
  margin: 0.3em;
}

.otherStoreCategoryDiv {
  display: inline-block;
}

.num {
  color: var(--primary-blue-400);
  border: 1px solid var(--primary-blue-400);
  background-color: var(--primary-blue-100);
  border-radius: 100%;
  padding: 1rem;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.hoverBox {
  top: 0;
  left: 0rem;
  max-width: 20rem;
  z-index: 100;
  position: absolute;
  background-color: white;

  border: 1px solid var(--primary-border);

  padding: 1rem;
}

.hoverBox1 {
  top: 3rem;
  left: 1rem;
  max-width: 20rem;
  z-index: 100;
  position: absolute;
  background-color: white;

  border: 1px solid var(--primary-border);

  padding: 1rem;
}

.hoverBoxHeader {
  font-size: 14px;
  font-weight: 600;
}

.hoverBoxP {
  font-size: 12px;
  margin-bottom: 1rem;
}

.colorText {
  color: var(--primary-purple);
  font-weight: 600;
}

.payTitle {
  font-weight: 600;
}

.onHover {
  cursor: pointer;
}

.onHover:hover {
  color: var(--primary-purple);
}

.stripeLogo {
  width: 10em;
}

.payPrice {
  color: var(--primary-purple);
  font-weight: 600;
  text-align: end;
}

.payInfo {
  font-size: 16px;
  font-style: italic;
  color: gray;
  margin-bottom: 1em;
  border-bottom: solid rgba(235, 235, 235, 0.822) 1px;
  max-width: 80%;
}

.marginInline {
  -webkit-margin-start: 1em;
  -webkit-margin-end: 1em;
  margin-inline: 2em;
}

.error {
  color: var(--primary-red-400);
  text-align: center;
  padding: 1em;
  padding-bottom: 0rem;
  margin-bottom: 0rem;
  font-size: 16px;
}

.errorDate1 {
  color: var(--primary-red-400);
  font-size: 16px;
}

.paymentButton {
  background-color: var(--primary-purple) !important;
  border: 0 !important;
  margin-top: 30px;
  height: 3rem;

  width: 100%;
}

.paymentButtonDisabled {
  background-color: var(--primary-purple) 4a !important;
  border: 0 !important;
  margin-top: 30px;
  height: 3rem;

  width: 100%;
}

.disabledPaymentButton {
  background-color: var(--primary-purple) 6f !important;
  border: 0 !important;
  margin-top: 30px;
  height: 3rem;
  width: 100%;
}

.submitOrderDisabled {
  width: 100%;
  background-color: var(--primary-purple) 4a !important;
  color: white;
  padding: 0.7em;
  margin-top: 1rem;
  text-align: center;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  border: 0px;
}

.white {
  margin-left: 0.2rem;
  height: 1.2rem;
  width: 1.2rem;
  color: white !important;
}

.payment {
  background-color: #99a3a6 !important;
}

.protectedBox {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.protectTextBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.learnMore {
  text-decoration: underline;
  display: inline;
}
.learnMore:hover {
  cursor: pointer;
}

.protectHeader {
  font-size: 16px;
  font-weight: 600;
}
.protectBody {
  font-size: 14px;
  color: #6b6b6b;
  line-height: 20px;
  letter-spacing: -0.16px;
}

.shieldIcon {
  margin-top: auto;
  margin-bottom: auto;
  color: #68b368;
  height: 50px;
  width: 50px;
  margin-right: 0.75rem;
}

.cardLogo {
  max-height: 2em;
  max-width: 2em;
  display: inline;
}

.cardDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.cardPreview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  width: 100%;
  border: 0.5px solid rgba(189, 189, 189, 0.627);
  border-radius: 7px;
  margin-bottom: 0.5em;
  padding: 0.7em 0.4rem;
  color: gray;
  position: relative;
}

.cardPreviewActive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  width: 100%;
  border: 0.5px solid var(--primary-purple);
  border-radius: 7px;
  margin-bottom: 0.5em;
  padding: 0.7em 0.4rem;
  color: gray;
  position: relative;
}

.circle {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  width: 20px;
  height: 20px;

  margin-top: auto;
  margin-bottom: auto;
  border-radius: 100%;
  border: 0.5px solid #99a3a6;
}

.circleActive {
  margin-left: 0.75rem;
  margin-right: 0.75rem;

  width: 20px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 100%;
  border: 0.5px solid var(--primary-purple);
  background-color: var(--primary-purple);
}

.cardPreview:hover {
  cursor: pointer;
  color: white;
  background-color: rgba(168, 168, 168, 0.398);
  border: 0.5px solid white;
}

.cardPreviewAdd:hover {
  cursor: pointer;
}

.cardPreviewAdd {
  display: flex;
  align-items: center;
  padding: 0.4em;
  color: var(--primary-purple);
}

.cardNumber {
  text-align: left;
  width: 50%;
  margin-left: 2em;
  /* margin-right: 3em; */
  color: #6b6b6b;
}

.addIcon {
  color: var(--primary-purple);
  height: 24px;
  width: 24px;
  display: inline;
  margin-right: 0.75rem;
}

.addText {
  font-weight: 600;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
  height: 24px;
}

.removeIcon {
  color: #99a3a6;
  height: 25px;
  width: 25px;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  right: 1em;
}

.removeIcon:hover {
  cursor: no-drop;
}

.paymentMethodSection {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.orPayWith {
  width: 100%;
  text-align: center;
  font-weight: 600;
  padding: 1em;
}

.paypalPayment {
  width: 80%;

  margin: auto;
}

.submitOrder {
  border: 0px;
  width: 100%;
  background-color: var(--primary-purple);
  color: white;
  padding: 0.7em;
  margin-top: 1rem;
  text-align: center;
  border-radius: 7px;
  font-weight: 600;
  cursor: pointer;
}

.wrap {
  display: flex;
  flex-direction: row;
}

.categoryBox {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--space-mid);
  width: 100%;
}

.categoryPicker {
  width: 45%;
  display: inline-block;
}

.deleteCategory {
  color: var(--primary-red-500);
  display: inline-block;
  cursor: pointer;
  background-color: var(--primary-bg);
  height: 25px;
  width: 25px;
  padding: 5px;
  border: 1px solid var(--primary-border);
  border-radius: 100%;
}

.deleteCategory:hover {
  background-color: var(--primary-red-500);
  color: white;
}

.addCategory {
  width: 100%;
  color: #68b368;
  font-weight: 500;
  cursor: pointer;
}

.closeStore {
  display: inline-block;
  cursor: pointer;
  background-color: #68b368;
  color: white;
  border-radius: 4px;
  margin-left: 1em;
  padding: 0.3em;
}

.closedStore {
  display: inline-block;
  cursor: pointer;
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-border);
  color: black;
  border-radius: 4px;
  margin-left: 1em;
  padding: 0.3em;
}

.loadingBox {
  display: flex;
  flex-direction: column;

  row-gap: 1.5rem;
}

.loadingRow {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.loadingSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  padding: 0.5rem;
}

.rowPlaceholder {
  width: 100%;
  height: 20px;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
}

.boxPlaceholder {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
}

.rowPlaceholderSmall {
  width: 50%;
  height: 20px;
  border-radius: 5px;
  background-size: 200% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 98%) 0%,
    hsl(0, 0%, 80%) 50%,
    hsl(0, 0%, 85%) 100%
  );
  animation: anim 2s linear infinite;
}

@media (max-width: 600px) {
  .checkoutHeader {
    text-align: center;
    font-size: 28px;
    margin-bottom: 3.25rem;
  }

  /* only the h1 that has .header */

  .header1 {
    margin-left: 1.5rem;
  }

  .paragraph {
    width: 100%;
    max-width: 100%;
  }

  .col1 {
    width: 100%;
  }

  .chackoutPage {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .leftSide {
    width: 100%;
    padding-bottom: 5rem;
  }

  .checkoutSummary {
    width: 100%;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .mainText2 {
    font-weight: 600;
    font-size: 13px;
  }

  .timePickerText {
    font-size: 12px;
  }

  .total {
    font-size: 14px;
  }

  .prices {
    font-size: 13px;
  }

  .listingInfo {
    font-size: 13px;
  }

  .pickUpInfo {
    font-size: 14px;
  }

  .mainText1 {
    width: 130px;
    min-width: 130px;
  }

  .timesInputsBox {
    flex-direction: column;
  }

  .mainTextSummary {
    font-size: 14px;
  }

  .subTextMain {
    font-size: 14px;
  }

  .title {
    width: 150px;
  }

  .colSectionBg {
    width: 100%;
  }

  .timePickerSection {
    column-gap: 1rem;
  }

  .box {
    width: 100%;
  }

  .activeSettings {
    flex-direction: column;
  }

  .pickupsAndViewings {
    width: 100%;
    margin-bottom: 1rem;
  }

  .wrap {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  .continueButton {
    font-size: 14px;
  }
}

@keyframes anim {
  0%,
  100% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
}
