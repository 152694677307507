.overflow {
	position: absolute;
	max-height: 20em;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	overflow-y: scroll;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	top: 40px;
	display: flex;
	flex-direction: column;
	gap: 0rem;
	padding: 0;
	margin: 0;

	background-color: white;
	z-index: 2;
	width: 100%;
}

.overflowError {
	position: absolute;
	max-height: 20em;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	overflow-y: scroll;
	border: 1px dashed var(--primary-red-400);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	top: 40px;

	padding: 0px;
	background-color: white;
	z-index: 2;
	width: 100%;
}

.input {
	border: 0;
	width: 100%;
	height: 100%;
	outline: none;
}

.main {
	position: relative;
}

.deleteIcon {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	width: 1.2rem;
	height: 1.2rem;
	color: var(--text-main);
}
.loadingSpinner {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	width: 1.5rem;
	height: 1.5rem;
	color: var(--primary-purple);
}

.spinner {
	animation: rotate 1.5s linear infinite;
}

.deleteIcon:hover {
	cursor: pointer;
}

.locationIcon {
	width: 12px;
	height: 12px;
	min-width: 12px;
	min-height: 12px;
	margin-bottom: 2px;
}
.inputVal {
	height: 47px;
	position: relative;
	cursor: pointer;
	-webkit-margin-start: auto;
	border: 1px solid var(--primary-border);
	border-radius: 4px;
	-webkit-margin-end: auto;
	margin-inline: auto;
	font-size: 14px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	margin-bottom: 0rem;
	padding: 12px 20px;
}
.inputVal::placeholder {
	color: var(--text-mute);
	font-size: 14px;
	opacity: 0.7;
}

.inputVal:focus {
	border: 1px solid var(--primary-purple);

	outline: none;
}

.inputValError {
	height: 47px;
	position: relative;
	cursor: pointer;
	-webkit-margin-start: auto;
	border: 1px dashed var(--primary-red-400);
	border-radius: 2px;
	-webkit-margin-end: auto;
	margin-inline: auto;
	font-size: 14px;
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 0rem;
	padding: 12px 20px;
}

.inputValError::placeholder {
	color: var(--text-mute);
	font-size: 14px;
}

.inputValError:focus {
	border: 1px solid var(--primary-red-400);
	outline: none;
}

.resultsListTop {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	text-decoration: underline;
	background-color: var(--primary-bg-light);
	font-size: 14px;
	padding: 10px 15px;
}

.resultsListTop:hover {
	font-weight: 600;
	background-color: rgba(243, 240, 240, 0.692);
	cursor: pointer;
}

.resultsList {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;

	font-size: 14px;
	padding: 10px 15px;
}

.resultsList:hover {
	font-weight: 600;
	background-color: rgba(243, 240, 240, 0.692);
	cursor: pointer;
	text-decoration: underline;
}

.text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 90%; /* Ensures the ellipsis works */
	margin-bottom: 0rem;
	font-size: 14px;
	font-weight: 400;
	font-family: 'Grotesk';
}

.text1 {
	font-size: 14px;
	display: flex;
	align-items: center;
	gap: 2px;
	margin-bottom: 0px;
	font-weight: 400;
	font-family: 'Grotesk';
}

.text2 {
	font-size: 14px;
	color: var(--text-body) !important;
	margin-bottom: 0;
	padding: 10px 15px;
}

.resultsListPick {
	position: relative;
	-webkit-margin-start: auto;
	border: 0;
	background-color: white;
	border-bottom: 1px solid #ced4da;
	-webkit-margin-end: auto;
	margin-inline: auto;
	margin-top: 1%;
	font-size: 14px;
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 0rem;
}

.resultsListPick2 {
	position: relative;
	-webkit-margin-start: auto;
	border: 0;
	background-color: white;
	border-bottom: 1px solid #ced4da;
	-webkit-margin-end: auto;
	margin-inline: auto;
	margin-top: 1%;
	font-size: 14px;
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 0rem;
}

.resultsListPick:hover {
	font-weight: 600;
	background-color: rgba(255, 255, 255, 0.692);
	cursor: pointer;
}

@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}
