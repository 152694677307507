/* Profile */

.main {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.section {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--primary-border);
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
}

.gap1 {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.main {
  height: 100%;
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: fit-content;

  position: relative;
}

.label {
  color: var(--text-main);
  font-size: 14px;
}

.saveButton {
  display: flex;
  position: relative;
  height: fit-content;
  text-transform: capitalize;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: var(--text-main);
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  color: white;
  padding: 7px 10px;
  font-family: "Editorial";
  width: fit-content;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-left: auto;
}

.saveButton:hover {
  opacity: 0.8;
  color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
