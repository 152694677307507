.buttonWrapper {
  display: flex;
  position: relative;
  height: 49px;
  width: 200px;
  align-items: center;
  border: 1px solid var(--primary-border);
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  cursor: pointer;
}

.dropDownButton {
  font-size: 14px;
}

.wrapperText {
  position: absolute;
  background-color: white;
  color: var(--text-mute);
  z-index: 2;
  top: -8px;
  left: 6px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 10px;
}

.buttonWrapper:hover {
  border: 1px solid var(--text-mute);
}

.buttonWrapper:hover span {
  color: var(--text-main);
}

.menu {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  border: 1px solid var(--primary-border);
  margin-top: 1rem;
  padding: 5px;
  border-radius: 3px;
  transition: 0.2s linear;
}

.menu:hover {
  border: 1px solid var(--text-mute);
  transition: 0.2s linear;
}

.item {
  font-size: 14px;
  cursor: pointer;
  color: var(--text-mute);
}

.item:hover {
  color: var(--text-main);
}
