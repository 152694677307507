.planDetails {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
}

.SellerPlan {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.link {
  font-size: 14px;
  color: var(--primary-purple);
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease;
}

.link:hover {
  opacity: 0.7;
}

.link:active {
  opacity: 0.5;
  transform: scale(0.98);
}
.title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.plan {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  padding: 1.5rem;
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  background-color: white;
  transition: all 0.3s ease;
}

.planActive {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  padding: 1.5rem;
  border: 1px solid var(--primary-purple);
  box-shadow: var(--box-shadow-1);
  border-radius: 5px;
  background-color: white;
  transition: all 0.3s ease;
}
.badgeActive {
  background-color: var(--primary-purple);
  color: white;
  border: 1px solid var(--primary-purple);
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease;
  cursor: pointer;
  transition: all 0.3s ease;
}

.subPlanTitle {
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-text);
  font-family: "Editorial";
}

.subPlanDescription {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.threshhold {
  font-size: 14px;
  color: var(--text-main);
  font-weight: 500;
}

.price {
  font-size: 14px;
  color: var(--text-body);
}

.subPlanText {
  font-size: 12px;
  margin-bottom: 0rem;
  color: var(--text-body);
}

.options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
}

.optionsTitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.optionsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

.option {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  columns: var(--text-body);
}

.checkMark {
  color: var(--primary-purple);
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.buttonOutlineDark {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Editorial";
  font-weight: 400;
  color: var(--text-main);

  margin-bottom: 0rem;
  font-size: 16px;
  border-radius: 3px;
  white-space: nowrap;
  border: 1px solid var(--text-main);
  background-color: transparent;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.buttonOutlineDark:hover {
  background-color: var(--primary-bg-light);
  scale: 1.01;
}

.buttonOutlineDark:active {
  background-color: var(--primary-bg-light);
  scale: 0.99;
}

@media (max-width: 600px) {
  .title {
    margin-left: 1.5rem;
  }
  .planDetails {
    grid-template-columns: 1fr;
  }
}
